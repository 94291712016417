<template>
    <el-card>

        <el-tabs v-model="activeName">
            <el-tab-pane label="个人资料" name="first">个人资料</el-tab-pane>
            <el-tab-pane label="修改密码" name="second">修改密码</el-tab-pane>
        </el-tabs>
        {{userData.username}}
        {{userData}}
    </el-card>
</template>

<script>
import { getUserData } from '@/utils/auth'

export default {
    data() {
        return {
            activeName: 'second',
            userData: getUserData()
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>

</style>